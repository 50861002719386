import { Helmet } from "react-helmet";
import { MainEvent } from "../components/home-main-event";
import { MakeIt } from "../components/home-make-it";
import { Speakers } from "../components/home-speakers";
import { Sponsors } from "../components/home-sponsors";
import { StayTuned } from "../components/home-stay-tuned";
import { HomeTopic } from "../components/home-topic";
import { VenueSlider } from "../components/home-venue-slider";
import { MediaCommunity2024 } from "../components/2024-media-and-community";

export default function HomePage() {
  const currentDomain =
    window?.location?.origin ?? `https://2023.vietnamblockchainsummit.com`;

  return (
    <div className="mx-auto w-full">
      <Helmet>
        <meta property="og:url" content={currentDomain} />
        <meta
          property="og:image"
          content={`${currentDomain}/assets/logo1200.png`}
        />
        <meta
          name="twitter:image"
          content={`${currentDomain}/assets/logo1200.png`}
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:title"
          content="Vietnam Blockchain Summit 2024 | VBS 2024 | HCMC, Vietnam"
        />
        <meta
          property="og:description"
          content="Vietnam Blockchain Summit (VBS) is the largest and most high-profile Blockchain event in Vietnam and surrounding regions"
        />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="601" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="Vietnam Blockchain Summit 2024 | VBS 2024 | HCMC, Vietnam"
        />
        <meta
          name="twitter:description"
          content="Vietnam Blockchain Summit (VBS) is the largest and most high-profile Blockchain event in Vietnam and surrounding regions"
        />
      </Helmet>
      <div className="w-full relative mx-auto flex items-center flex-col bg-cover-blockchain">
        <div className="my-8 sm:my-20 flex flex-col items-center justify-center container mx-auto px-4">
          <img
            src={process.env.PUBLIC_URL + `/assets/viet-nam-blockchain.png`}
            alt="VIETNAM BLOCKCHAIN SUBMIT 2024"
          />
          <p
            className="mt-4"
            style={{
              color: "#FFF",
              fontFamily: "Inter",
              fontSize: "1.47719rem",
              fontStyle: "normal",
              fontWeight: "500",
              lineHeight: "normal",
            }}
          >
            Vietnam's Largest & Most High-Profile Blockchain Event
          </p>
        </div>
        <div className="inline-flex flex-col lg:flex-row gap-x-9 container mx-auto px-4 justify-center gap-y-6">
          <a
            href="https://form.jotform.com/223543189590058"
            target="_blank"
            rel="noreferrer"
            className="bg-btn text-Barlow text-center text-xl sm:text-[28px] leading-[34px] text-white font-bold rounded-[20px] py-4 sm:py-[29px] px-4 sm:px-20"
          >
            BECOME A SPONSOR
          </a>
          <a
            href="https://t.me/VietnamBlockchainSummit"
            target="_blank"
            className="bg-btn-outlet2 text-Barlow text-center text-xl sm:text-[28px] leading-[34px] text-white font-bold rounded-[20px] py-4 sm:py-[29px] px-4 sm:px-11 uppercase"
            rel="noreferrer"
          >
            Connect with us
          </a>
        </div>
        <div className="w-full px-4">
          <HomeTopic />
        </div>
        <div className="mt-[84px] py-24 w-full">
          <VenueSlider />
        </div>
      </div>
      <div className="bg-main-event">
        <MainEvent />
      </div>

      <div className="bg-make-it min-h-[942px] -mt-10">
        <MakeIt />
        {/* <Agenda /> */}
        <Speakers />
        <Sponsors />
        <MediaCommunity2024/>
        {/* <MediaPartner />
        <CommunityPartner /> */}
        <StayTuned />
        {/* <Recap2022 /> */}
        {/* <TicketComponent /> */}
      </div>
    </div>
  );
}
