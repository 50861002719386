export default function TravelGuidePage() {
  return (
    <div className="mx-auto w-full relative px-4 lg:px-0">
      <div className="bg-travel-guide h-[819px] absolute left-0 right-0"></div>
      <div className="container mx-auto relative z-30">
        <h2 className="text-travel">Travel Guide</h2>
        <h3 className="text-accommodation">Accommodations</h3>
        <p className="text-description mx-auto max-w-[54.125rem]">
          Here are the three highest-rated hotels based on user reviews on the
          Traveloka app. For a wider selection of hotels, we recommend exploring
          other reputable platforms such as Agoda, Booking.com, and similar
          apps.
        </p>

        <div className="grid grid-cols-1 lg:grid-cols-3 gap-[2.19rem] mt-8">
          <div className="col-span-1 rounded-2xl overflow-hidden bg-[#252394] bg-opacity-10 border-[2.5px] border-[#fff] border-opacity-20">
            <img
              src={`${process.env.PUBLIC_URL}/assets/CaravelleSaigon 2.png`}
              className="w-full"
            />
            <h4 className="font-Anton text-[2rem] leading-[2.4rem] text-center text-white tracking-[0.0125rem] px-4 py-6">
              Lotte Hotel Saigon
            </h4>
          </div>
          <div className="col-span-1 rounded-2xl overflow-hidden bg-[#252394] bg-opacity-10 border-[2.5px] border-[#fff] border-opacity-20">
            <img
              src={`${process.env.PUBLIC_URL}/assets/CaravelleSaigon 2 (1).png`}
              className="w-full"
            />
            <h4 className="font-Anton text-[2rem] leading-[2.4rem] text-center text-white tracking-[0.0125rem] px-4 py-6">
              Hotel Nikko Saigon
            </h4>
          </div>
          <div className="col-span-1 rounded-2xl overflow-hidden bg-[#252394] bg-opacity-10 border-[2.5px] border-[#fff] border-opacity-20">
            <img
              src={`${process.env.PUBLIC_URL}/assets/CaravelleSaigon 2 (2).png`}
              className="w-full"
            />
            <h4 className="font-Anton text-[2rem] leading-[2.4rem] text-center text-white tracking-[0.0125rem] px-4 py-6">
              Hotel des Arts Saigon MGallery Collection
            </h4>
          </div>
        </div>

        <div className="mt-[10.81rem]">
          <h3 className="text-h3">Activities and Sightseeings</h3>

          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-[2.19rem]">
            {[
              {
                des: "The Independence Palace",
                title: "Dinh Độc Lập",
                image: "Component 18.png",
              },
              {
                des: "Notre Dame Cathedral Basilica of Saigo",
                title: "Nhà Thờ Đức Bà",
                image: "Component 19.png",
              },
              {
                des: "Saigon Central Post Office",
                title: "Bưu điện Trung tâm",
                image: "Component 20.png",
              },
              {
                des: "Bui Vien Walking Street",
                title: "Phố đi bộ Bùi Viện",
                image: "Component 20 (1).png",
              },
              {
                des: "War Remnants Museum",
                title: "Bảo tàng Chứng tích Chiến tranh",
                image: "Component 21.png",
              },
              {
                des: "Ben Thanh Market",
                title: "Chợ Bến Thành",
                image: "Component 22.png",
              },
            ].map((i) => {
              return (
                <div
                  key={i.title}
                  className="col-span-1 relative border-[2.5px] border-[#fff] border-opacity-20 rounded-2xl overflow-hidden"
                >
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/${i.image}`}
                    className="w-full"
                  />
                  <div className="absolute top-0 left-0 right-0 z-10 p-6">
                    <h3 className="activities-title">{i.title}</h3>
                    <p className="activities-des pt-2 text-left">{i.des}</p>
                  </div>
                </div>
              );
            })}
          </div>

          {/* <p className="box-activities-des mx-auto mt-8">
            Experience the ultimate convenience with the hop-on hop-off bus, an
            ideal choice for those with limited time. Discover renowned
            historical sites in Ho Chi Minh City as you effortlessly embark and
            disembark at any station, while enjoying informative audio
            narrations on board.
          </p>

          <div className="flex items-center justify-center mt-8">
            <div className="inline-flex mx-auto flex-row items-center justify-center gap-x-[1.69rem] box-activities-btn">
              Hop on Hop off Bus
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={60}
                height={24}
                viewBox="0 0 60 24"
                fill="none"
              >
                <path
                  d="M59.0607 13.0607C59.6464 12.4749 59.6464 11.5251 59.0607 10.9393L49.5147 1.3934C48.9289 0.807616 47.9792 0.807616 47.3934 1.3934C46.8076 1.97919 46.8076 2.92894 47.3934 3.51472L55.8787 12L47.3934 20.4853C46.8076 21.0711 46.8076 22.0208 47.3934 22.6066C47.9792 23.1924 48.9289 23.1924 49.5147 22.6066L59.0607 13.0607ZM-1.31134e-07 13.5L58 13.5L58 10.5L1.31134e-07 10.5L-1.31134e-07 13.5Z"
                  fill="white"
                />
              </svg>
            </div>
          </div> */}
        </div>

        <div className="mt-[10.81rem]">
          <h3 className="text-h3">Hop on hop off bus</h3>
          <div className="flex flex-col md:flex-row justify-between items-start mt-8">
            <div className="w-full md:w-2/5 pr-0 md:pr-6">
              <p className="text-[1.375rem] leading-snug text-white">
                Experience the ultimate convenience with the hop-on hop-off bus,
                an ideal choice for those with limited time. Discover renowned
                historical sites in Ho Chi Minh City as you effortlessly embark
                and disembark at any station, while enjoying informative audio
                narrations on board.
              </p>
              <a
                href="https://www.klook.com/vi/experiences/list/ho-chi-minh-city-hop-on-hop-off/c33-cate11/"
                target="_blank"
                className="text-[1.375rem] leading-snug text-white font-bold mt-[4.19rem] underline mb-8 md:mb-0 inline-block"
              >
                Saigon Hop-on Hop-off Bus Pass
              </a>
            </div>
            <div className="w-full md:w-3/5 flex justify-end">
              <img src={process.env.PUBLIC_URL + "/assets/image 23.png"} />
            </div>
          </div>
        </div>

        <div className="mt-[10.81rem]">
          <h3 className="text-h3">Restaurants & Cafés</h3>
          <p className="box-activities-des mx-auto mt-8">
            Discover the top six highly acclaimed restaurants and coffee shops
            on the renowned TripAdvisor platform, offering a diverse range of
            culinary styles. For additional options, we encourage you to explore
            further at TripAdvisor.
          </p>

          <div className="grid grid-cols-1 sm:grid-cols-2 gap-[2.19rem] mt-8">
            {[
              {
                title: "Noir Restaurant",
                image: "Component 35 (2).png",
              },
              {
                title: "Kabin",
                image: "Component 36 (2).png",
              },
              {
                title: "Latest Recipe",
                image: "Component 35 (1).png",
              },
              {
                title: "Little HaNoi Egg Coffee",
                image: "Component 36 (1).png",
              },
              {
                title: "Villa Royale Antiques & Tea Room",
                image: "Component 35.png",
              },
              {
                title: "The Melbourne Cafe Restaurant",
                image: "Component 36.png",
              },
            ].map((i) => {
              return (
                <div
                  key={i.title}
                  className="col-span-1 relative border-[2.5px] border-[#fff] border-opacity-20 rounded-2xl overflow-hidden"
                >
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/restaurant/${i.image}`}
                    className="w-full"
                  />
                  <div className="absolute bottom-[1.38rem] left-[1.68rem] right-[1.68rem] z-10 p-6">
                    <h3 className="activities-des text-center">{i.title}</h3>
                  </div>
                </div>
              );
            })}
          </div>
        </div>

        <div className="mt-[10.81rem]">
          <h3 className="text-h3">Transportation</h3>
          <p className="box-activities-des mx-auto mt-8">
            To enhance your convenience, we suggest utilizing well-known
            applications like Grab, Gojek, Be, and Xanh VSM to book
            transportation services. These platforms offer seamless booking
            experiences and reliable transportation options for your travel
            needs.
          </p>
          <div className="flex flex-row items-center justify-around lg:justify-between gap-4 flex-wrap mt-16">
            <img
              src={`${process.env.PUBLIC_URL}/assets/transport/Frame 331.png`}
              className="max-w-[160px] sm:max-w-[250px]"
            />
            <img
              src={`${process.env.PUBLIC_URL}/assets/transport/Frame 332.png`}
              className="max-w-[160px] sm:max-w-[250px]"
            />
            <img
              src={`${process.env.PUBLIC_URL}/assets/transport/Frame 333.png`}
              className="max-w-[160px] sm:max-w-[250px]"
            />
            <img
              src={`${process.env.PUBLIC_URL}/assets/transport/Frame 334.png`}
              className="max-w-[160px] sm:max-w-[250px]"
            />
          </div>
        </div>

        {/* <div
          style={{
            borderRadius: "1.25rem",
            background: "linear-gradient(149deg, #2A3BE1 0%, #E412F1 100%)",
            minHeight: "12.3125rem",
          }}
          className="p-4 flex items-center justify-center my-[10.81rem]"
        >
          <p className="text-visa max-w-[54.125rem]">
            And finally, for outside Vietnam foreigners who want to enter
            Vietnam can personally apply for E-visa{" "}
            <a
              href="https://evisa.xuatnhapcanh.gov.vn/en_US/web/guest/khai-thi-thuc-dien-tu/cap-thi-thuc-dien-tu"
              target="_blank"
              className="text-visa-gradient"
            >
              here.
            </a>
          </p>
        </div> */}

        <div className="my-[10.81rem] bg-visa">
          <div className="flex flex-col md:flex-row">
            <p
              className="w-full md:w-7/12 text-white"
              style={{
                fontFamily: "Roboto",
                fontSize: "2.25rem",
                fontStyle: "normal",
                fontWeight: "700",
                lineHeight: 1.5,
                letterSpacing: "0.0125rem",
              }}
            >
              And finally, for outside Vietnam foreigners who want to enter
              Vietnam can personally apply for E-visa{" "}
              <a
                href="https://evisa.xuatnhapcanh.gov.vn/en_US/web/guest/khai-thi-thuc-dien-tu/cap-thi-thuc-dien-tu"
                target="_blank"
                className="text-visa-gradient"
              >
                here.
              </a>
            </p>
            <p
              className="w-full md:w-5/12 mt-[80px] md:mt-[203px] text-white text-visa-2"
              style={{
                fontFamily: "Roboto",
                fontSize: "1.375rem",
                fontStyle: "normal",
                fontWeight: "400",
                lineHeight: "1.925rem" /* 140% */,
                letterSpacing: "0.00625rem",
              }}
            >
              Hope you will have a great time with Vietnam Blockchain Summit
              2024 and a worthy traveling experience in Vietnam.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
