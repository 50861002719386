import { Autoplay, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { SponsorData } from "../store/sponsor-data";
import { chunk } from "../util/chunk";

export function Sponsors() {
  const pagination = {
    clickable: true,
    renderBullet: function (index: number, className: string) {
      return '<span class="' + className + '"></span>';
    },
  };

  const data = chunk(SponsorData, SponsorData.length);

  return (
    <div className="bg-sponsors">
      <div className="container mx-auto py-[107px] px-4">
        <h3 className="uppercase text-white font-Barlow font-bold text-4xl sm:text-5xl md:text-[54px] text-center">
          Past <span className="sub-gradient2">Sponsors</span>
        </h3>

        <Swiper
          spaceBetween={0}
          slidesPerView={1}
          centeredSlides={true}
          autoplay={{
            delay: 5000,
            disableOnInteraction: false,
          }}
          allowTouchMove={false}
          modules={[Autoplay, Pagination]}
          pagination={pagination}
          className="mySwiper-sponsors mt-10"
        >
          {data.map((item, ind) => {
            return (
              <SwiperSlide key={`sponsors-${ind}`}>
                <div className="flex flex-wrap gap-6 md:gap-10 lg:gap-16 justify-between items-center">
                  {item.map((i, index) => {
                    return (
                      <a
                        key={`sponsors-${ind}-${index}`}
                        href={i.link}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img
                          className="object-contain max-w-[150px] sm:max-w-[200px] md:max-w-[250px] max-h-[80px]"
                          src={
                            process.env.PUBLIC_URL + `/assets/sponsors/${i.img}`
                          }
                          alt=""
                        />
                      </a>
                    );
                  })}
                </div>
              </SwiperSlide>
            );
          })}
        </Swiper>

        <div className="flex items-center justify-center">
          <a
            href="https://form.jotform.com/223543189590058"
            target="_blank"
            className="rounded-[20px] bg-btn uppercase font-Barlow font-bold text-[28px] leading-none text-white px-6 sm:px-[79px] py-[29px] text-center"
            rel="noreferrer"
          >
            Become a 2024 sponsor
          </a>
        </div>
      </div>
    </div>
  );
}
