import { Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

export function VenueSlider() {
  const data = [
    `${process.env.PUBLIC_URL}/assets/slider1.png`,
    `${process.env.PUBLIC_URL}/assets/slider2.png`,
    `${process.env.PUBLIC_URL}/assets/slider3.png`,
    `${process.env.PUBLIC_URL}/assets/slider4.png`,
    `${process.env.PUBLIC_URL}/assets/slider5.png`,
  ];

  return (
    <div className="w-full relative">
      <Swiper
        spaceBetween={0}
        slidesPerView={1}
        centeredSlides={true}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        allowTouchMove={false}
        modules={[Autoplay]}
        className="mySwiper-venue"
      >
        {data.map((item) => {
          return (
            <SwiperSlide key={`${item}`}>
              <div
                style={{ background: `url(${item}) center no-repeat` }}
                className="min-h-[452px]"
              ></div>
            </SwiperSlide>
          );
        })}
      </Swiper>
      <div className="absolute inset-0 flex items-center justify-center z-10 px-4">
        <p className="text-white font-Roboto text-[22px] leading-[1.09] text-center">
          Venue
          <span className="font-Barlow font-bold text-5xl leading-[59px] block pb-4 pt-4">
            Thisky Hall Convention Center | HCMC
          </span>
          July 2024
        </p>
      </div>
    </div>
  );
}
