const image = [
    'speaker-trungphan.png',
    'speaker-kristina.png',
    'speaker-james-wo.png',
    'speaker-sarahbuxton.png',
    "speaker-joanna.png.png",
    "speaker-joshuafoo.jpeg.png",
    "speaker-truong-gia-binh.jpg.png",
    "speaker-victortran.jpg.png",
    "speaker-micahdominguez.jpg.png",
    "speaker-lynnhoang.jpeg.png",
    "speaker-thu-aura.png.png",
    "speaker-andrew-vranjes.png.png",
    "speaker-rileytran.jpg.png",
    "speaker-nadia-taiga.png.png",
    "speaker-binh-tran.png.png",
    "speaker-tonygilbert.png.png",
    "speaker-hiennguyen.jpg.png",
    "speaker-thanhnguyen.png.png",
    "speaker-john-chen.jpg.png",
    "speaker-toya-zhang.jpg.png",
    "speaker-nangnguyen.jpg.png",
    "speaker-dinhtran.jpg.png",
    "speaker-paul-kang.png.png",
    "speaker-dmitriy-sheludko.png.png",
    "speaker-corbin-fraser.png.png",
    "speaker-charu-sethi.png.png",
    "speaker-helena-wang.jpeg.png",
    "speaker-lee-zhu.jpeg.png",
    "speaker-tu-fpt2.png.png",
    "speaker-jinnie-lee.jpg.png",
    "speaker-leo-vu.jpeg.png",
    "speaker-john-nguyen-kai.png.png",
    "speaker-le-nguyen.png.png",
    "speaker-john-cattrall.png.png",
    "speaker-hoan-pham.png.png",
    "speaker-quinn-campbell.jpeg.png",
    "speaker-natalie.png.png",
    "speaker-hao-tran.jpeg.png",
    "speaker-soohyuk-choi.png.png",
    "speaker-thanh-dao.png.png",
    "speaker-linh-han.png.png",
    "speaker-dinh-ngoc-thanh.png.png",
    "speaker-heng.jpeg.png",
    "speaker-nichlas-soong.jpeg.png",
    "speaker-bao-tran.png.png",
    "speaker-don.png.png",
    "speaker-nftpunk.jpeg.png",
    'sponsor-duy.png',
]

const name = [
    'Trung Phan',
    'Kristina Lucrezia Cornèr',
    'James Wo',
    'Sarah ‘BUX’ Buxton',
    'Joanna Liang',
    'Joshua Foo',
    'Truong Gia Binh',
    'Victor Tran',
    'Micah Dominguez',
    'Lynn Hoang',
    'Thu Tran',
    'Andrew Vranjes',
    'Riley Tran',
    'Nadia Taiga',
    'Binh Tran',
    'Toby Gilbert',
    'Hien Nguyen',
    'Thanh Nguyen',
    'John Chen',
    'Toya Zhang',
    'Nang Nguyen',
    'Dinh Tran',
    'Paul Kang',
    'Dmitriy Sheludko',
    'Corbin Fraser',
    'Charu Sethi',
    'Helena Wang',
    'Lee Zhu',
    'Tu Vu',
    'Jinnie Lee',
    'Leo Vu',
    'John Nguyen',
    'Nguyen Son Hoang Le',
    'John Cattrall',
    'Hoan Pham',
    'Quinn Campbell',
    'Natalie Sit',
    'Tran Nguyen Hao',
    'Albert Soohyuk Choi',
    'Dao Hoang Thanh',
    'Linh Han',
    'Đinh Ngọc Thạnh',
    'Heng Yu Lee',
    'Nicholas Soong',
    'Brian Tran',
    'Don Phan',
    'James Olden',
    'Trinh Cong Duy PhD',
]
const office = [
    `Chairman, Decom Holdings Standing Vice President, Vietnam Blockchain Association`,
    `Editor-in-Chief, Cointelegraph`,
    `Founder and CEO, DFG (Digital Finance Group)`,
    `COO, Gala`,
    `Co-Founder & CEO, Jsquare`,
    `Regional Director (ASEAN, HK & TW), Chainalysis`,
    `Chairman, FPT Corporation`,
    `CEO, Kyber Network`,
    `Strategic Partnerships Director, FIO Protocol`,
    `Director, Binance`,
    `COO Aura Network`,
    `General Manager and VP of Sales APAC at Blockdaemon`,
    `Country Director, NEAR Vietnam Co-founder, GFS Ventures`,
    `Executive and Curatorial Director Snark.Art`,
    `General Partner Ascend Vietnam Ventures`,
    `CEO & Co-Founder Coinweb`,
    `Chairwoman, IMG Group Head of Policy and Legal Committee - Member of Standing Committee, Vietnam Blockchain Association`,
    `Non Executive Chairman, VeriChains Lab Vice President of IT, VNG`,
    `CEO, MEXC Global`,
    `CMO, Bit.com`,
    `Product Manager, TomoChain Labs Head of Operations, LUA Ventures`,
    `Founder & CEO, AlphaTrue Member of the Central Committee, Vietnam Blockchain Association`,
    `Co-Founder & CEO Entersoft`,
    `CEO Biconomy`,
    `Head of Financial Services Bitcoin.com`,
    `Chief Marketing Officer Unique Network`,
    `Head of Parity APAC Parity Technologies`,
    `Ecosystem BNB Chain`,
    `CTO FPT Corporation`,
    `Head of Global Partnerships Klaytn Foundation`,
    `CEO Fizen.io Web3 Digital Bank`,
    `VP Kardia Ventures`,
    `Investment Associate Ascend Vietnam Ventures`,
    `ASEAN Senior Solutions Architect - Web3 Amazon Web Services`,
    `Vice Director National Electronic Authentication Centre of Vietnam`,
    `VP of Growth Sky Mavis`,
    `Group CEO Acestar Sdn Bhd`,
    `Founder & CEO Vietcetera`,
    `Founder / Chairman SymVerse Co., Ltd., / KOREA Blockchain Startup Association`,
    `Founder / CTO LaunchZone`,
    `Founder Hectagon DAO & HUB Global`,
    `Co-Founder VBI Institute`,
    `Partner DWF Labs`,
    `Director of OKX Chain Ecosystem Development, Asia Pacific OKX Chain`,
    `CEO ONUS Chain`,
    `Web3 Business Development Manager Amazon Web Services`,
    `CSO Gala`,
    `Founder Bizverse World`,
]


export const SpeakerData = image.map((i, index) => {
    return { img: i, name: name[index], office: office[index] }
})