const name = [
    `Cointelegraph`,
    `e27`,
    `BeInCrypto`,
    `VIETNAMINSIDER`,
    `HotQA`,
    `TheAnh28 Entertainment`,
    `UToday`,
    `Cryptopolitan`,
    `Block Tides`,
    `Coinspeaker`,
    `FinancialMarkets.media`,
    `Bitcoin Garden`,
    `Securities.io`,
    `CoinCheckup.com`,
    `MeKongAsean`,
    `TheInsideOut`,
    `Web3 Insider`,
    `CryptoInsider`,
    `Merger`,
    `ASIA INSIDER`,
    `Market INSIDER`,
    `VIETNAM JOURNAL`,
    `ZEX PR WIRE`,
    `VIETNAM STAR`,
    `CoinsPaid Media`,
    `ICOHOLDER`,
    `COINGAPE`,
    `METAHUB`,
    `Việt Nam News`,
    `Coincu `,
    `CoinPedia Fintech News`,
    `BITCOIN MEXICO`,
    `NEWS BTC`,
    `BITCOIN-TRADING`,
    `Crypto Runner`,
    `Coincodex`,
    `Coinvestasi`,
    `Crypto Officiel`,
    `Bitcoin World`,
    `COINVN`,
    `Coin Edition`,
    `Block Sol `,
    `COINBOLD`,
    `Crypto Nauts`,
    `MMTGroup`,
    `TK.Ventures (chainplay)`,
    `CoinWire`,
    `Followin`,
    `Crypto Rank`,
    `ChainCatcher`,
    `Asia Token Fund`,
]

const link = [
    `https://cointelegraph.com/`,
    `https://e27.co/`,
    `https://vn.beincrypto.com/`,
    `https://vietnaminsider.vn/vi/`,
    `https://hotqa.io/?fbclid=IwAR2fPAyYapWRDnzlEn4K4EZc3ZrZ4mIU1R47uVCAjm2IYyWfcOQzMp5YItw`,
    `https://theanh28.vn/`,
    `https://u.today/`,
    `https://www.cryptopolitan.com/`,
    `https://www.blocktidesmedia.com/`,
    `https://www.coinspeaker.com/`,
    `https://www.financialmarkets.media/`,
    `https://bitcoingarden.org/`,
    `https://www.securities.io/`,
    `https://coincheckup.com/`,
    `https://mekongasean.vn/`,
    `https://insideout.vn/`,
    `https://web3insider.info/`,
    `https://www.facebook.com/yourcryptoinsider/`,
    ``,
    `https://twitter.com/asiainsiders`,
    `https://twitter.com/MktsInsider?ref_src=twsrc%5Egoogle%7Ctwcamp%5Eserp%7Ctwgr%5Eauthor`,
    ``,
    `https://zexprwire.com/`,
    `https://vietnamstar.net/`,
    `https://coinspaidmedia.com/`,
    `https://icoholder.com/`,
    `https://coingape.com/`,
    `https://wemeta.gg/`,
    `https://vietnamnews.vn/`,
    `https://coincu.com/vi/`,
    `https://coinpedia.org/`,
    `https://www.bitcoin.com.mx/`,
    `https://www.newsbtc.com/`,
    ``,
    `https://cryptorunner.com/`,
    `https://coincodex.com/`,
    `https://coinvestasi.com/`,
    `https://twitter.com/CryptoOfficiel`,
    `https://bitcoinworld.co.in/`,
    `https://coinvn.com/`,
    `https://coinedition.com/`,
    `https://block-sol.com/`,
    `https://coinbold.net/`,
    ``,
    `https://www.facebook.com/TradecoinMMT/`,
    `https://chainplay.gg/vi/investor/tk-ventures/`,
    `https://coinwire.com/`,
    `https://followin.io/`,
    `https://cryptorank.io/`,
    `https://www.chaincatcher.com/`,
    `https://asiatokenfund.com/`,
]

const image = [
    `ccc.png`,
    `kih.png`,
    `ji.png`,
    `vietnaminsider.png`,
    `amban.png`,
    `theanh28.png`,
    `utoday.png`,
    `cryptopolitan.png`,
    `blocktides.jpg`,
    `mk.png`,
    `financialmarkets.png`,
    `ad.png`,
    `securitiesio.png`,
    `coincheckup.png`,
    `mekongasean.png`,
    `insideout.png`,
    `Web3Insider.png`,
    `cryptoinsider.png`,
    `merger-logo-1.png`,
    `Logo-Asia-Insiders.png`,
    `Market-Insider.png`,
    `vietnamjournal.png`,
    `zexprwire--white.png`,
    `media-partner-vietnamstar.png`,
    `coinspadmedia.png`,
    `icoholder-logo-9F7595E15D-seeklogo.com.png`,
    `aa.png`,
    `logo2.png`,
    `viet_nam_news-logo_0.png`,
    `coincu_color.png`,
    `5.png`,
    `f.png`,
    `newsbtc.png`,
    `media-partner-blockchain-trading2.png`,
    `run.png`,
    `media-partner-coincodex.png`,
    `k.png`,
    `crypto-logo-1.png`,
    `b.png`,
    `wef.png`,
    `weff.png`,
    `media-partner-blocksol1.png`,
    `logo-dark.png`,
    `naut.png`,
    `mmt.png`,
    `j.png`,
    `conewire.png`,
    `Followin_logo.png`,
    `LogoMain.png`,
    `few.png`,
    `asia.png`,
]




export const MediaPartnerData = image.map((i, index) => {
    return { img: i, link: link[index], name: name[index] }
})
