import React from "react";
import { Link } from "react-router-dom";
import { Autoplay, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { CommunityPartnerData } from "../store/community-partner-data";
import { MediaPartnerData } from "../store/media-partner-data";
import { SpeakerData } from "../store/speaker-data";
import { SponsorData } from "../store/sponsor-data";
import { chunk } from "../util/chunk";

const pagination = {
  clickable: true,
  renderBullet: function (index: number, className: string) {
    return '<span class="' + className + '"></span>';
  },
};

const dataRecap = [
  "Copy of KVU08236.JPG",
  "Copy of KDUY5376.jpg",
  "Copy of KVU07941.JPG",
  "Copy of KVU07942.JPG",
  "Copy of IMG07609.jpg",
  "Copy of KVU06707.jpg",
  "KTKT5396.jpg",
  "Copy of KVU06748.jpg",
  "Copy of KVU07429.jpg",
  "Copy of KVU08298.JPG",
  "Copy of KVU06711.jpg",
];

export default function PastEventPage() {
  const ref = React.useRef<HTMLDivElement | null>(null);

  const [height, setHeight] = React.useState(670);

  const data = React.useMemo(() => {
    return chunk(SponsorData, 16);
  }, []);

  const speaker = React.useMemo(() => {
    return chunk(SpeakerData, 16);
  }, []);

  React.useEffect(() => {
    if (!ref?.current) {
      return () => {};
    }
    const resize = () => {
      if (!ref?.current) {
        return;
      }
      const h = (ref.current.offsetWidth * 9) / 16;
      setHeight(h);
    };
    window.addEventListener("resize", resize);
    return () => {
      window.removeEventListener("resize", resize);
    };
  }, []);

  return (
    <>
      <div className="absolute top-0 overflow-hidden z-10 left-0 right-0 flex justify-center">
        <img
          src={process.env.PUBLIC_URL + "/assets/bg-pastevent.png"}
          style={{ width: 1920, height: 737 }}
        />
      </div>
      <div className="mx-auto w-full relative px-4 lg:px-0 container z-20">
        <div className="flex mx-auto justify-center items-center mt-24">
          <div className="inline-flex mx-auto justify-center flex-col items-start max-w-[970px]">
            <img src={process.env.PUBLIC_URL + "/assets/past-event1.png"} />
            <img
              src={process.env.PUBLIC_URL + "/assets/past-event2.png"}
              className="mt-8"
            />
          </div>
        </div>

        {/* <h2 className="text-travel">Shaping the future</h2> */}
        <p className="text-description mx-auto pt-20">
          In 2022 we gathered more than 2,000 blockchain technology enthusiasts
          from more than 30 countries and received support from 100+ sponsors &
          partners in the industry! All gave us such a powerful energy boost for
          the 1st edition.
        </p>

        <div className="w-full mt-[2.19rem]" ref={ref}>
          <iframe
            width="100%"
            height={height}
            src="https://www.youtube.com/embed/xh4hASQ4znw?iv_load_policy=3&modestbranding=1&autoplay=1&rel=0&disablekb=1&enablejsapi=1&showinfo=0&playslinline=1&loop=1&mute=1"
            title="YouTube video player"
            allow="accelerometer; autoplay; mute;clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
            frameBorder="0"
            style={{ borderRadius: "16px", overflow: "hidden" }}
          ></iframe>
        </div>

        <div className="mt-[9rem]">
          <h3 className="uppercase text-white font-Barlow font-bold text-4xl sm:text-5xl md:text-[54px] text-center">
            Past <span className="sub-gradient2">Sponsors</span>
          </h3>

          <div className="mx-0 xl:-mx-10 mt-10">
            <div className="w-full bg-[#0F0A4A] px-10 py-6 rounded-[1.25rem]">
              <Swiper
                spaceBetween={0}
                slidesPerView={1}
                centeredSlides={true}
                autoplay={{
                  delay: 5000,
                  disableOnInteraction: false,
                }}
                allowTouchMove={false}
                modules={[Autoplay, Pagination]}
                pagination={pagination}
                className="mySwiper-sponsors mt-10"
              >
                {data.map((item, ind) => {
                  return (
                    <SwiperSlide key={`sponsors-${ind}`}>
                      <div className="flex flex-wrap gap-6 md:gap-10 lg:gap-16 justify-between items-center">
                        {item.map((i, index) => {
                          return (
                            <a
                              key={`sponsors-${ind}-${index}`}
                              href={i.link}
                              target="_blank"
                              rel="noreferrer"
                            >
                              <img
                                className="object-contain max-w-[150px] sm:max-w-[200px] md:max-w-[250px] max-h-[80px]"
                                src={
                                  process.env.PUBLIC_URL +
                                  `/assets/sponsors/${i.img}`
                                }
                                alt=""
                              />
                            </a>
                          );
                        })}
                      </div>
                    </SwiperSlide>
                  );
                })}
              </Swiper>
            </div>
          </div>

          <div className="flex items-center justify-center mt-10">
            <a
              href="https://form.jotform.com/223543189590058"
              target="_blank"
              className="rounded-[20px] bg-btn uppercase font-Barlow font-bold text-[28px] leading-none text-white px-6 sm:px-[79px] py-[29px] text-center"
              rel="noreferrer"
            >
              Become a 2024 sponsor
            </a>
          </div>
        </div>

        <div className="mt-[9rem]">
          <h3 className="uppercase text-white font-Barlow font-bold text-4xl sm:text-5xl md:text-[54px] text-center">
            Past <span className="sub-gradient2">Speakers</span>
          </h3>

          <div className="mx-0 xl:-mx-10 mt-12">
            <Swiper
              spaceBetween={0}
              slidesPerView={1}
              centeredSlides={true}
              autoplay={{
                delay: 5000,
                disableOnInteraction: false,
              }}
              allowTouchMove={false}
              modules={[Autoplay, Pagination]}
              pagination={pagination}
              className="mySwiper-speakers"
            >
              {speaker.map((item, index) => {
                return (
                  <SwiperSlide key={`speakers-${index}`}>
                    <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-[6px]">
                      {item.map((k, i) => {
                        return (
                          <div
                            className="col-span-1 border border-[#CCCCCC] border-dashed flex items-center justify-center flex-col p-4 pb-10"
                            key={`speakers-${index}-${i}`}
                          >
                            <img
                              src={
                                process.env.PUBLIC_URL +
                                `/assets/speakers/${k.img}`
                              }
                              alt=""
                              className="max-w-[180px] max-h-[180px]"
                            />
                            <h4 className="sub-gradient2 mt-4 font-Montserrat font-bold text-xl leading-[1.2] text-center">
                              {k.name}
                            </h4>
                            <p className="mt-2 font-Montserrat text-white leading-[1.2] text-center">
                              {k.office}
                            </p>
                          </div>
                        );
                      })}
                    </div>
                  </SwiperSlide>
                );
              })}
            </Swiper>

            <div className="flex items-center justify-center mt-12">
              <a
                href="https://form.jotform.com/223491524231045"
                target="_blank"
                className="rounded-[20px] bg-btn uppercase font-Barlow font-bold text-[28px] leading-none text-white px-6 sm:px-[79px] py-[29px] text-center"
                rel="noreferrer"
              >
                Become a 2024 speaker
              </a>
            </div>
          </div>
        </div>

        <div className="mt-[9rem]">
          <h3 className="container mx-auto uppercase text-white font-Barlow font-bold text-4xl sm:text-5xl md:text-[54px] text-center">
            Past <span className="sub-gradient2">Media Partners</span>
          </h3>
          <div className="mx-0 xl:-mx-10 mt-20">
            <div className="w-full bg-[#0F0A4A] px-10 py-12 rounded-[1.25rem]">
              <div className="flex flex-row flex-wrap gap-6 md:gap-10 lg:gap-16 justify-between items-center">
                {MediaPartnerData.map((i, index) => {
                  return (
                    <a
                      href={i.link}
                      target="_blank"
                      rel="noreferrer"
                      key={`media-partner-${index}`}
                    >
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          `/assets/media-partners/${i.img}`
                        }
                        alt={`${i.img}`}
                        className="object-contain max-w-[150px] sm:max-w-[200px] md:max-w-[250px] max-h-[80px]"
                      />
                    </a>
                  );
                })}
              </div>
            </div>
          </div>

          <div className="flex items-center justify-center mt-20">
            <a
              href="https://form.jotform.com/223469183033455"
              target="_blank"
              className="rounded-[20px] bg-btn uppercase font-Barlow font-bold text-[28px] leading-none text-white px-6 sm:px-[79px] py-[29px] text-center"
              rel="noreferrer"
            >
              Become a 2024 Media partner
            </a>
          </div>
        </div>

        <div className="mt-[9rem]">
          <h3 className="container mx-auto uppercase text-white font-Barlow font-bold text-4xl sm:text-5xl md:text-[54px] text-center">
            Past <span className="sub-gradient2">community Partners</span>
          </h3>
          <div className="mx-0 xl:-mx-10 mt-20">
            <div className="w-full bg-[#0F0A4A] px-10 py-20  rounded-[1.25rem]">
              <div className="flex flex-row flex-wrap gap-6 md:gap-10 lg:gap-16 justify-between items-center">
                {CommunityPartnerData.map((i, index) => {
                  return (
                    <a
                      href={i.link}
                      target="_blank"
                      rel="noreferrer"
                      key={`media-partner-${index}`}
                    >
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          `/assets/community-partners/${i.img}`
                        }
                        alt={`${i.img}`}
                        className="object-contain max-w-[150px] sm:max-w-[200px] md:max-w-[250px] max-h-[80px]"
                      />
                    </a>
                  );
                })}
              </div>
            </div>
          </div>

          <div className="flex items-center justify-center mt-20">
            <a
              href="https://form.jotform.com/223469183033455"
              target="_blank"
              className="rounded-[20px] bg-btn uppercase font-Barlow font-bold text-[28px] leading-none text-white px-6 sm:px-[79px] py-[29px] text-center"
              rel="noreferrer"
            >
              Become a 2024 community partner
            </a>
          </div>
        </div>

        <div className="mt-[9rem]">
          <h3 className="container mx-auto uppercase text-white font-Barlow font-bold text-4xl sm:text-5xl md:text-[54px] text-center">
            Vietnam Blockchain Summit 2022 created{" "}
            <span className="sub-gradient2">memorable</span> occasions that{" "}
            <span className="sub-gradient2">bring people together</span>
          </h3>

          <div className="mt-10">
            <Swiper
              spaceBetween={0}
              slidesPerView={1}
              centeredSlides={true}
              autoplay={{
                delay: 5000,
                disableOnInteraction: false,
              }}
              allowTouchMove={false}
              modules={[Autoplay, Pagination]}
              pagination={pagination}
              className="mySwiper-recap"
            >
              {dataRecap.map((item, index) => {
                return (
                  <SwiperSlide key={`recap-${index}`}>
                    <img
                      src={process.env.PUBLIC_URL + `/assets/recap/${item}`}
                      alt={item}
                      className="max-h-[280px] sm:max-h-[420px] md:max-h-[490px] lg:max-h-[689px] mx-auto"
                    />
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </div>
        </div>

        <div className="mt-[9rem]">
          <h3 className="container mx-auto uppercase text-white font-Barlow font-bold text-4xl sm:text-5xl md:text-[54px] text-center">
            What attendees said about{" "}
            <span className="sub-gradient2">VBS 2022</span>
          </h3>

          <div className="flex flex-row gap-y-8 gap-x-[4.5rem] mt-[3.44rem] items-start flex-wrap lg:flex-nowrap">
            <img
              src={`${process.env.PUBLIC_URL}/assets/about-said/Frame 306.png`}
              className="w-full max-w-[30.875rem] object-contain"
            />
            <div className="flex flex-col gap-y-6">
              <div>
                <img
                  src={`${process.env.PUBLIC_URL}/assets/about-said/phay.png`}
                  className="max-w-[7.78806rem]"
                />
              </div>
              <p className="text-white text-[2.25rem] leading-[3.375rem] tracking-[0.00625rem]">
                "Congrats on hosting such a phenomenon summit! I would like to
                represent DFG and thank the VBS team! You've been very
                supportive since our first conversation. We appreciate your
                great effort, patience, and hospitality."
              </p>
              <div
                style={{
                  background: "linear-gradient(7deg, #0072FF 0%, #06F98F 100%)",
                  width: "12.0625rem",
                  height: "0.1875rem",
                }}
              ></div>
              <div className="flex flex-row gap-x-[2.38rem]  items-center">
                <p className="text-white text-lg">- DFG Team</p>
                <img
                  src={`${process.env.PUBLIC_URL}/assets/about-said/dfg.png`}
                />
              </div>
            </div>
          </div>

          <div className="flex flex-row-reverse gap-[2rem] mt-[7.25rem] items-center flex-wrap lg:flex-nowrap">
            <img
              src={`${process.env.PUBLIC_URL}/assets/about-said/Frame 304.png`}
              className="w-full max-w-[30.875rem]"
            />
            <div className="flex flex-col gap-y-6">
              <div>
                <img
                  src={`${process.env.PUBLIC_URL}/assets/about-said/phay.png`}
                  className="max-w-[7.78806rem]"
                />
              </div>
              <p className="text-white text-[2.25rem] leading-[3.375rem] tracking-[0.00625rem]">
                Great event and initiative! Thanks for having us, excited to be
                supporting the Web3 ecosystem in Vietnam together!
              </p>
              <div
                style={{
                  background: "linear-gradient(7deg, #0072FF 0%, #06F98F 100%)",
                  width: "12.0625rem",
                  height: "0.1875rem",
                }}
              ></div>
              <div className="flex flex-row gap-x-[2.38rem]  items-center">
                <p className="text-white text-lg">- AWS Team</p>
                <img
                  src={`${process.env.PUBLIC_URL}/assets/about-said/amazon.png`}
                />
              </div>
            </div>
          </div>

          <div className="flex flex-col mt-[7.25rem] max-w-[63.4375rem] mx-auto gap-y-8">
            <img
              src={`${process.env.PUBLIC_URL}/assets/about-said/phay.png`}
              className="max-w-[7.78806rem]"
            />

            <p className="text-white text-[2.25rem] leading-[3.375rem] tracking-[0.00625rem]">
              Vietnam Blockchain Summit is over but the echo is undoubtedly
              standing still!
              <br />
              <br />
              During the event, there were so many inspiring and informative
              speech conducted by worldly-wise Blockchain/Digital Asset
              Management experts, with a wide range of topics from Metaverse,
              NFTs, Defi, Gamefi, Socialfi,... I have carried with me all the
              opinions, ideas and critical thoughts of them with me to the next
              step of my career in the field!
              <br />
              <br />
              Let alone the fact that i also met up with so many admirable and
              impressive partners there, from everywhere all around the world.
              It is so amazing to see many Blockchain enthusiasts from many
              places caring about the industry, and specifically, its potential
              growth in Vietnam.
            </p>
            <p className="text-white text-lg text-right">- An attendee</p>
          </div>

          <div className="flex items-center justify-center my-[9rem]">
            <Link
              to="/"
              className="rounded-[20px] bg-btn uppercase font-Barlow font-bold text-[28px] leading-none text-white px-6 sm:px-[79px] py-[29px] text-center"
              rel="noreferrer"
            >
              Join us for 2024
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}
