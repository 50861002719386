import {
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
} from "react-router-dom";
import { LayoutMain } from "./layout/main";
import NoMatch from "./no-match";
import HomePage from "./pages/home";
import PastEventPage from "./pages/past-event";
import TravelGuidePage from "./pages/travel-guide";

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<LayoutMain />}>
      <Route index element={<HomePage />} />
      <Route path="/travel-guide" element={<TravelGuidePage />} />
      <Route path="/past-event" element={<PastEventPage />} />
      <Route path="*" element={<NoMatch />} />
    </Route>
  )
);

function App() {
  return <RouterProvider router={router} />;
}

export default App;
