export function MainEvent() {
  const data = [
    {
      img: `${process.env.PUBLIC_URL}/assets/events/event1.png`,
      label: "Gather the leading voices in Web3",
    },
    {
      img: `${process.env.PUBLIC_URL}/assets/events/event2.png`,
      label: "Training Workshops",
    },
    {
      img: `${process.env.PUBLIC_URL}/assets/events/event3.png`,
      label: "Founder-Investor Matching",
    },
    {
      img: `${process.env.PUBLIC_URL}/assets/events/event4.png`,
      label: "Exclusive After Party",
    },
    {
      img: `${process.env.PUBLIC_URL}/assets/events/event5.png`,
      label: "Networking Zone",
    },
    {
      img: `${process.env.PUBLIC_URL}/assets/events/event6.png`,
      label: "Display cutting edge products and services of Web3",
    },
  ];

  return (
    <div className="container mx-auto">
      <div className="xl:-mx-[18px] pb-24 overflow-hidden">
        <div className="w-full lg:w-4/12 px-4 lg:pt-24 float-left">
          <p
            style={{
              background:
                "linear-gradient(81deg, #0072FF -29.36%, #06F98F 29.11%)",
              backgroundClip: "text",
              WebkitTextFillColor: "transparent",
              WebkitBackgroundClip: "text",
              fontFamily: "Roboto",
              fontSize: "1.125rem",
              fontStyle: "normal",
              fontWeight: "700",
              lineHeight: "1.6875rem",
            }}
          >
            VBS 2024:
          </p>
          <h3 className="text-white font-Barlow text-4xl leading-[1.67] font-bold">
            VBS: STAY ON THE PATH
          </h3>
          <p className="text-white font-Roboto text-[18px] leading-normal mt-4">
            In 2022 we gathered more than 2,000 blockchain technology
            enthusiasts from more than 30 countries and received support from
            100+ sponsors & partners in the industry! All gave us such a
            powerful energy boost. And what were more, we saw the positive
            reaction to Vietnam's developed blockchain ecosystem as well as the
            openness to learn, to experience and to achieve of a
            technology-driven, early-adopter mentality kind of Vietnam
            community, so now we are ready for something even more spectacular!
          </p>
        </div>
        <div className="w-full lg:w-8/12 px-4 pt-4 lg:pt-24 float-left">
          {/* https://www.youtube.com/embed/A5P7teaH9ms?iv_load_policy=3&modestbranding=1&rel=0&showinfo=0&enablejsapi=1&playslinline=1&origin=https%3A%2F%2Fkoreablockchainweek.com&playlist=A5P7teaH9ms&loop=1&autoplay=0&controls=0&widgetid=1 */}
          <iframe
            width="100%"
            height="450x"
            src="https://www.youtube.com/embed/xh4hASQ4znw?iv_load_policy=3&modestbranding=1&autoplay=1&rel=0&disablekb=1&enablejsapi=1&showinfo=0&playslinline=1&loop=1&mute=1"
            title="YouTube video player"
            allow="accelerometer; autoplay; mute;clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
            frameBorder="0"
            style={{ borderRadius: "16px", overflow: "hidden" }}
          ></iframe>
        </div>
      </div>

      <div className="w-full mt-4 lg:mt-14">
        <h3 className="text-center font-Roboto text-[28px] leading-normal text-white px-4">
          We believe that we do more than just{" "}
          <span className="sub-gradient1 font-bold">organize events</span>
          <br /> We create{" "}
          <span className="sub-gradient1 font-bold">
            memorable occasions
          </span>{" "}
          that bring people together.
        </h3>

        <div className="mt-[60px] px-4 lg:px-6 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
          {data.map((item) => {
            return (
              <div
                key={`event-${item.img}`}
                className="col-span-1 relative group overflow-hidden cursor-pointer item-event"
              >
                <img
                  src={item.img}
                  alt=""
                  className="w-full transform transition"
                />
                <div
                  className="absolute inset-0 z-10"
                  style={{
                    background:
                      "linear-gradient(180.04deg, rgba(7, 52, 157, 0) 44.4%, #062B83 94.17%)",
                  }}
                ></div>
                <p className="absolute left-0 right-0 bottom-11 px-4 font-Anton text-base leading-snug text-white z-20 text-center">
                  {item.label}
                </p>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
