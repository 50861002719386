const name = [
    `FPT`,
    `DFG`,
    `JSquare`,
    `OKX`,
    `Chainalysis`,
    `Binance`,
    `BingX`,
    `AWS`,
    `XTATUZ`,
    `MEXC Global`,
    `Aura Network`,
    `CESS`,
    `Bitget`,
    `ONUS Chain`,
    `Meduverse`,
    `Ekoios`,
    `Carmin Labs`,
    `CoinW`,
    `HotQA`,
    `apM Coin`,
    `Playground`,
    `Bizverse`,
    `Jeritex`,
    'Iverse',
    `Franck Muller`,
    `AAX`,
    `FTX`,

]

const link = [
    `https://fpt.vn/vi`,
    `https://www.dfg.group/`,
    `https://www.jsquare.co/`,
    `https://www.okx.com/vi`,
    `https://demo.chainalysis.com/request-a-demo/?utm_source=google&utm_medium=cpc&utm_campaign=dra-search-brand&utm_content=demo&utm_category=dra-search-brand_apac_search&utm_term=chainalysis&utm_source=google&utm_medium=cpc&utm_campaign={campaign}&utm_term=chainalysis&utm_content=627795095883&gclid=CjwKCAjw8-OhBhB5EiwADyoY1aex6EKOnUCmx-_jmbR0eTMICFqBj27KlQp5H6K8S3T8DG9LykQ8SxoCfckQAvD_BwE`,
    `https://www.binance.com/vi`,
    `https://bingx.com/vi-vn/login/`,
    `https://aws.amazon.com/`,
    `https://www.xtatuz.com/`,
    `https://www.mexc.com/`,
    `https://aura.network/`,
    `https://cess.cloud/`,
    `https://www.bitget.com/vi/`,
    `https://goonus.io/`,
    `https://meduverse.vn/`,
    `https://ekoios.vn/`,
    `https://carmin.inc/`,
    `https://www.coinw.com/`,
    `https://hotqa.io/?fbclid=IwAR2fPAyYapWRDnzlEn4K4EZc3ZrZ4mIU1R47uVCAjm2IYyWfcOQzMp5YItw`,
    `https://apm-coin.com/`,
    ``,
    `https://bizverse.io/?lang=vi`,
    `https://jeritexeu.com/en/`,
    ``,
    `https://twitter.com/FranckMullerLab?ref_src=twsrc%5Egoogle%7Ctwcamp%5Eserp%7Ctwgr%5Eauthor`,
    `https://www.facebook.com/AAXVietnam`,
    `https://ftx.com/`,
]

const image = [
    `LogFPT-01.png`,
    `DFGlogowhite.png`,
    `Jsquarelogowhite.png`,
    `okx.png`,
    `Chainalysis-logo.png`,
    `Binance-Horizontal.png`,
    `bing.png`,
    `AWS_logo_RGB_REV.png`,
    `XTATUZ.png`,
    `1.png`,
    `Aura-logo-2.png`,
    `CESSlogowhit.png`,
    `ki.png`,
    `LogoONUS_main.png`,
    `LogoMeduverse.png`,
    `logolk.png`,
    `CARMINSYMBOLWORDMARK-12.png`,
    `coinw.png`,
    `hotqa.png`,
    `logo_apm_white_edit.png`,
    `PG-TextMark-Tangerine.png`,
    `vrfairs_tran.png`,
    `logo-color2.png`,
    `Asset1.png`,
    `white.png`,
    `AAX_Official_Logo.png`,
    `ftx.png`,
]




export const SponsorData = image.map((i, index) => {
    return { img: i, link: link[index], name: name[index] }
})
