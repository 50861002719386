import { Autoplay, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { SpeakerData } from "../store/speaker-data";
import { chunk } from "../util/chunk";

export function Speakers() {
  const data = chunk(SpeakerData, 12);

  const pagination = {
    clickable: true,
    renderBullet: function (index: number, className: string) {
      return '<span class="' + className + '"></span>';
    },
  };

  return (
    <div id="Speakers" className="container mx-auto pb-[128px] pt-[8rem] px-4">
      <h3 className="uppercase text-white font-Barlow font-bold text-4xl sm:text-5xl md:text-[54px] text-center ">
        Past <span className="sub-gradient2">Featured Speakers</span>
      </h3>

      <Swiper
        spaceBetween={0}
        slidesPerView={1}
        centeredSlides={true}
        autoplay={{
          delay: 5000,
          disableOnInteraction: false,
        }}
        allowTouchMove={false}
        modules={[Autoplay, Pagination]}
        pagination={pagination}
        className="mySwiper-speakers my-10"
      >
        {data.map((item, index) => {
          return (
            <SwiperSlide key={`speakers-${index}`}>
              <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-[6px]">
                {item.map((k, i) => {
                  return (
                    <div
                      className="col-span-1 border border-[#CCCCCC] border-dashed flex items-center justify-center flex-col p-4 pb-10"
                      key={`speakers-${index}-${i}`}
                    >
                      <img
                        src={
                          process.env.PUBLIC_URL + `/assets/speakers/${k.img}`
                        }
                        alt=""
                        className="max-w-[180px] max-h-[180px]"
                      />
                      <h4 className="sub-gradient2 mt-4 font-Montserrat font-bold text-xl leading-[1.2] text-center">
                        {k.name}
                      </h4>
                      <p className="mt-2 font-Montserrat text-white leading-[1.2] text-center">
                        {k.office}
                      </p>
                    </div>
                  );
                })}
              </div>
            </SwiperSlide>
          );
        })}
      </Swiper>

      <div className="flex items-center justify-center">
        <a
          href="https://form.jotform.com/223491524231045"
          target="_blank"
          className="rounded-[20px] bg-btn uppercase font-Barlow font-bold text-[28px] leading-none text-white px-6 sm:px-[79px] py-[29px] text-center"
          rel="noreferrer"
        >
          Become a 2024 speaker
        </a>
      </div>
    </div>
  );
}
