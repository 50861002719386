export function StayTuned() {
  return (
    <div id="Tickets" className="pb-24 pt-[88px] relative">
      <div className="absolute inset-0 bg-stay z-10"></div>
      <h3 className="container mx-auto uppercase text-white font-Barlow font-bold text-[54px] text-center z-20 relative">
        STAY TUNED FOR <span className="sub-gradient2">2024</span>
      </h3>
      <div className="w-full py-8  border-t border-b border-white px-4 overflow-hidden flex flex-row  my-10 z-30 relative">
        <p className="font-Anton text-white text-5xl sm:text-6xl md:text-7xl lg:text-8xl px-8 whitespace-nowrap inline-block animate-transform">
          Tickets will be available soon. Tickets will be available soon.
          Tickets will be available soon. Tickets will be available soon.
          Tickets will be available soon. Tickets will be available soon.
          Tickets will be available soon. Tickets will be available soon.
          Tickets will be available soon. Tickets will be available soon.
          Tickets will be available soon. Tickets will be available soon.
          Tickets will be available soon. Tickets will be available soon.
          Tickets will be available soon. Tickets will be available soon.
          Tickets will be available soon. Tickets will be available soon.
          Tickets will be available soon. Tickets will be available soon.
          Tickets will be available soon. Tickets will be available soon.
          Tickets will be available soon. Tickets will be available soon.
          Tickets will be available soon. Tickets will be available soon.
          Tickets will be available soon. Tickets will be available soon.
          Tickets will be available soon. Tickets will be available soon.
          Tickets will be available soon. Tickets will be available soon.
          Tickets will be available soon. Tickets will be available soon.
          Tickets will be available soon. Tickets will be available soon.
          Tickets will be available soon. Tickets will be available soon.
          Tickets will be available soon. Tickets will be available soon.
          Tickets will be available soon. Tickets will be available soon.
          Tickets will be available soon. Tickets will be available soon.
          Tickets will be available soon. Tickets will be available soon.
          Tickets will be available soon. Tickets will be available soon.
        </p>
        <p className="font-Anton text-white text-5xl sm:text-6xl md:text-7xl lg:text-8xl px-8 whitespace-nowrap  opacity-0">
          Tickets will be available soon. Tickets will be available soon.
        </p>
      </div>
    </div>
  );
}
