import { useCallback } from "react";
import type { Location, useMatches } from "react-router-dom";
import { Outlet, ScrollRestoration } from "react-router-dom";
import { FooterLayout } from "./footer";
import { HeaderLayout } from "./header";

export function LayoutMain() {
  const getKey = useCallback(
    (location: Location, matches: ReturnType<typeof useMatches>) => {
      let match = matches.find((m) => (m.handle as any)?.scrollMode);
      if ((match?.handle as any)?.scrollMode === "pathname") {
        return location.pathname;
      }

      return location.key;
    },
    []
  );

  return (
    <main className="bg-[#181164] mx-auto w-full min-h-screen text-white overflow-x-hidden">
      <HeaderLayout />
      <div className="pt-[76px] lg:pt-[96px]"></div>
      <Outlet />
      <FooterLayout />
      <ScrollRestoration getKey={getKey} />
    </main>
  );
}
