export function MakeIt() {
  return (
    <div className="container mx-auto lg:min-h-[800px] flex flex-col justify-center py-[142px]">
      <h3 className="text-white text-left font-Barlow text-3xl sm:text-[49px] leading-[1.67] font-bold px-4 lg:px-0">
        LET’S <span className="sub-gradient1">MAKE IT</span> TOGETHER!
      </h3>
      <p className="text-white text-left font-Roboto text-[18px] leading-normal mt-4 max-w-[630px] px-4 lg:px-0">
        {`Vietnam Blockchain Summit 2024 will be the distinguished flagship event of Vietnam Blockchain Week 2024. Various independently organized side events add to VBS's presence, creating a week of non-stop learning and networking. During the week, expect a wide range of meetups, workshops, hackathons, dinners and parties in exceptional venues across Vietnam.`}
      </p>
    </div>
  );
}
