const name = [
    `K300 Ventures`,
    `Bsc Daily`,
    `TradeCoin Viet Nam`,
    `METAPAC`,
    `Diễn Đàn Phổ Cập Blockchain`,
    `TIỀN THUẬT TOÁN`,
    `METAWORK`,
    `Blogtienao`,
    `APAC DAO`,
    `BTCmonday`,
    `Bitcoin.com`,
    `CMC Capital`,
    `Bmoon`,
    `Beng Beng Gaming`,
    `CoinTV VENTURES`,
    `BD VENTURES`,
    `VBC Ventures`,
    `VIC Group`,
    'Chill Group',
    `CRYPTOHOLIC`,
    `9 CAT DIGITAL`,
    `ONUS`,
    `Crypto Bilis`,
    `Cryptoviet`,
    `Crypto Insight`,
    `HT VENTURES`,
    `Kretos Ventures`,
    `Blockchain Network Philippines`,
    `The Lighthouse`,
]

const link = [
    `https://k300.ventures/`,
    `https://www.binance.com/vi/live/u/26755713`,
    `https://www.facebook.com/tradecoinvietnam1/`,
    `https://metapac.io/`,
    `https://phocapblockchain.net/`,
    `https://tienthuattoan.com/`,
    `https://metawork.network/`,
    `https://kretos.ventures/`,
    `https://apacdao.net/`,
    `https://btcmonday.com/`,
    `Bitcoin.com`,
    `https://cmccapital.io/`,
    `https://bmoon.io/`,
    `https://bengbenggaming.com/`,
    `https://cointv.ventures/`,
    `https://www.bdventures.vn/`,
    `https://vbc.ventures/`,
    `https://viccrypto.com/`,
    'https://www.facebook.com/groups/chillcryptocommunity/',
    `https://cryptoholicvn.com/`,
    `https://9catdigital.com/`,
    `https://goonus.io/`,
    `https://www.cryptobilis.com/`,
    ``,
    ``,
    `https://www.facebook.com/groups/htholdings.ventures/`,
    `https://kretos.ventures/`,
    `https://blockchainnetworkph.com/`,
    `https://twitter.com/TheLighthouseVN`,
]

const image = [
    `k300-logo-white.png`,
    `BSCdaily-01 black-01.png`,
    `tradecoinvn.png`,
    `metapac_side_all_pillar_white.png`,
    `amban.png`,
    `tienthuattoan.png`,
    `3_png.png`,
    `nu.png`,
    `qd.png`,
    `btcmonday.png`,
    `av.png`,
    `community-partner-cmccaptital.png`,
    `bm.png`,
    `logo11.png`,
    `cointv.png`,
    `bdventures.png`,
    `ttt.png`,
    `vicgroup.png`,
    'chillgroup.png',
    `ooo.png`,
    `logo-9cat-compress.png`,
    `onus.png`,
    `wed.png`,
    `wa.png`,
    `qw.png`,
    `htholdings.png`,
    `kre.png`,
    `BNP_Logo_CM_Secondary_v1.png`,
    `thelighthouse.png`,
]


export const CommunityPartnerData = image.map((i, index) => {
    return { img: i, link: link[index], name: name[index] }
})
