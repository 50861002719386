import { useEffect, useRef, useState } from "react";
import { Link, NavLink } from "react-router-dom";

let enter = true;

export function HeaderLayout() {
  const [show, setShow] = useState<boolean>(false);
  const ref = useRef<HTMLUListElement | null>(null);

  const menus = [
    {
      label: "VBS 2024",
      link: "/",
      target: "_self",
      sub: [
        // {
        //   label: "Agenda",
        //   link: "/#Agenda",
        //   target: "_self",
        // },
        {
          label: "Speakers",
          link: "/#Speakers",
          target: "_self",
        },
        {
          label: "Partners",
          link: "/#Partners",
          target: "_self",
        },
      ],
    },
    // {
    //   label: "VBW 2023",
    //   link: "https://vietnamblockchainweek.io",
    //   target: "_blank",
    // },
    {
      label: "Past Event",
      link: "/past-event",
      target: "_self",
    },
    {
      label: "Travel Guide",
      link: "/travel-guide",
      target: "_self",
    },
  ];

  useEffect(() => {
    const onClick = () => {
      if (enter || !ref.current) {
        return;
      }
      const lists = ref.current.getElementsByClassName("show");
      for (let i = 0; i < lists.length; i++) {
        const element = lists[i];
        if (element.classList.contains("show")) {
          element.classList.toggle("show");
        }
      }
    };

    document.addEventListener("click", onClick);

    return () => {
      document.removeEventListener("click", onClick);
    };
  }, []);

  return (
    <>
      <header className="w-full mx-auto bg-[#0F0740] bg-opacity-10 fixed z-50 backdrop-blur-lg">
        {/* <CountDown /> */}
        <div className="container mx-auto flex flex-row justify-between py-4 lg:py-5 px-4 items-center">
          <Link to={"/"}>
            <img
              src={`${process.env.PUBLIC_URL}/assets/logo.png`}
              alt="logo"
              className="max-w-[110px] lg:max-w-[141px]"
            />
          </Link>
          <div className="hidden invisible lg:visible lg:flex items-center justify-end">
            <ul
              className="flex items-center justify-center flex-row px-10 nav-menu"
              ref={ref}
            >
              {menus.map((item) => {
                return (
                  <li
                    key={`menu-${item.label}`}
                    className={item.sub ? "sub" : ""}
                    onPointerEnter={() => {
                      enter = true;
                    }}
                    onPointerLeave={() => {
                      enter = false;
                    }}
                    onClick={(e) => {
                      if (item.sub) {
                        e.preventDefault();
                        e.stopPropagation();
                      }
                    }}
                  >
                    <NavLink
                      to={item.link}
                      target={item.target}
                      className={`font-BoosterNextFY text-lg leading-6 text-white font-bold text-center`}
                      onClick={(e) => {
                        if (item.sub) {
                          const parent = e.currentTarget
                            .parentElement as HTMLLIElement;
                          if (parent) {
                            parent.classList.toggle("show");
                          }
                          e.preventDefault();
                        }
                      }}
                    >
                      {item.label}
                    </NavLink>
                    {item.sub ? (
                      <ul className="submenu">
                        {item.sub.map((i) => {
                          return (
                            <li key={`menu-${i.label}`}>
                              <NavLink
                                to={i.link}
                                target={i.target}
                                className={
                                  "font-BoosterNextFY text-lg leading-6 text-white font-bold text-center"
                                }
                              >
                                {i.label}
                              </NavLink>
                            </li>
                          );
                        })}
                      </ul>
                    ) : null}
                  </li>
                );
              })}
            </ul>
          </div>
          {/* <div className="hidden invisible lg:visible lg:flex items-center justify-end">
            <nav className="flex items-center justify-center flex-row gap-x-12 px-10">
              {menus.map((item) => {
                return (
                  <NavLink
                    key={`menu-${item.label}`}
                    to={item.link}
                    target={item.target}
                    className={`font-BoosterNextFY text-lg leading-6 text-white font-bold ${
                      item.sub ? "sub-menu" : ""
                    }`}
                  >
                    {item.label}
                    {item.sub ? (
                      <ul className="sub-menu-content">
                        {item.sub.map((i) => {
                          return (
                            <li key={`menu-${i.label}`}>
                              <NavLink
                                to={i.link}
                                target={i.target}
                                className={
                                  "font-BoosterNextFY text-lg leading-6 text-white font-bold"
                                }
                              >
                                {i.label}
                              </NavLink>
                            </li>
                          );
                        })}
                      </ul>
                    ) : null}
                  </NavLink>
                );
              })}
            </nav>
           
          </div> */}

          <div className="hidden invisible lg:visible lg:flex">
            <div className="flex items-center justify-center flex-row gap-x-8">
              {/* <NavLink
                to={"/#Tickets"}
                className="text-white bg-btn font-Barlow font-bold text-xl leading-6 w-[175px] h-[47px] text-center rounded-[10px] flex items-center justify-center"
              >
                BUY TICKET
              </NavLink> */}
              <a
                href="https://form.jotform.com/223543189590058"
                target="_blank"
                className="text-white bg-btn-outlet2 font-Barlow font-bold text-xl leading-6 w-[175px] h-[47px] text-center rounded-[10px]"
                rel="noreferrer"
              >
                BECOME A SPONSOR
              </a>
            </div>
          </div>
          <button
            className="flex items-center justify-center w-9 h-9 visible lg:invisible lg:hidden"
            onClick={() => {
              setShow(!show);
            }}
          >
            <img
              src={process.env.PUBLIC_URL + "/assets/menu.png"}
              className="w-10 h-10"
              alt="Menu"
            />
          </button>
        </div>
        <div
          className={`flex visible lg:invisible lg:hidden w-full top-[76px] mx-auto z-50 transform duration-500 ${
            show
              ? "max-h-[1000px] -translate-x-[0px]x"
              : "max-h-0 -translate-x-[130%]"
          }`}
        >
          <div className="container px-4 mx-auto">
            <nav className="flex flex-col gap-y-6 py-6">
              {menus.map((item) => {
                return (
                  <>
                    <NavLink
                      key={`menu-${item.label}`}
                      to={item.link}
                      target={item.target}
                      className={
                        "font-BoosterNextFY text-lg leading-6 text-white font-bold"
                      }
                    >
                      {item.label}
                    </NavLink>
                    {item.sub ? (
                      <div className="pl-8 flex flex-col gap-y-6">
                        {item.sub.map((i) => {
                          return (
                            <NavLink
                              key={`menu-${i.label}`}
                              to={i.link}
                              target={i.target}
                              className={
                                "font-BoosterNextFY text-lg leading-6 text-white font-bold"
                              }
                            >
                              {i.label}
                            </NavLink>
                          );
                        })}
                      </div>
                    ) : null}
                  </>
                );
              })}
            </nav>

            <div className="flex items-center justify-center flex-col sm:flex-row gap-y-4 gap-x-8 pb-4">
              {/* <NavLink
                to={"/#Tickets"}
                className="text-white bg-btn font-Barlow font-bold text-xl leading-6 w-[175px] h-[47px] text-center rounded-[10px] flex items-center justify-center"
              >
                BUY TICKET
              </NavLink> */}
              <a
                href="https://form.jotform.com/223543189590058"
                target="_blank"
                className="text-white bg-btn-outlet2 font-Barlow font-bold text-xl leading-6 w-[175px] h-[47px] text-center rounded-[10px]"
                rel="noreferrer"
              >
                BECOME A SPONSOR
              </a>
            </div>
          </div>
        </div>
      </header>
    </>
  );
}
