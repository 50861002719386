import { Link, NavLink } from "react-router-dom";

export function FooterLayout() {
  const menus = [
    {
      title: "VBW 2024*",
      link: "",
      target: "_self",
    },
    {
      title: "Become a Sponsor",
      link: "https://form.jotform.com/223543189590058",
      target: "_blank",
    },
    {
      title: "Apply to be a Speaker",
      link: "https://form.jotform.com/223491524231045",
      target: "_blank",
    },
    {
      title: "*: Coming Soon",
      link: "",
      target: "_self",
    },
    // {
    //   title: "Become a Media Partner",
    //   link: "https://form.jotform.com/223469183033455",
    //   target: "_blank",
    // },
    // {
    //   title: "Become a Community Partner",
    //   link: "https://form.jotform.com/231049198680058",
    //   target: "_blank",
    // },
    // // { title: "Apply to be an onsite Volunteer", link: "#", target: "_self" },
    // {
    //   title: "Media Kit",
    //   link: "https://drive.google.com/drive/folders/1sfjkvFtZIGxe6izH00Iu3pIeMbT4SeN6",
    //   target: "_blank",
    // },
  ];

  const menus2 = [
    {
      title: "Become a Media Partner",
      link: "https://form.jotform.com/223469183033455",
      target: "_blank",
    },
    {
      title: "Become a Community Partner",
      link: "https://form.jotform.com/223469183033455",
      target: "_blank",
    },
    { title: "Apply to be an onsite Volunteer*", link: "#", target: "_self" },
    {
      title: "Media Kit",
      link: "https://drive.google.com/drive/folders/1sfjkvFtZIGxe6izH00Iu3pIeMbT4SeN6",
      target: "_blank",
    },
  ];

  return (
    <footer className="w-full bg-footer pt-20 pb-32">
      <div className="container mx-auto">
        <div className="flex flex-row flex-wrap gap-[30px] justify-between px-4">
          <div className="flex flex-col">
            <p className="text-white font-Barlow font-bold text-[28px] leading-[34px] uppercase text-left">
              Get Involved
            </p>
            <nav className="flex flex-col gap-y-5 mt-8">
              {menus.map((i) => {
                if (i.link.length === 0) {
                  return (
                    <p
                      key={i.title}
                      className="text-white font-Barlow font-normal text-[22px] leading-[26px] uppercase text-left cursor-pointer select-none"
                    >
                      {i.title}
                    </p>
                  );
                }

                return (
                  <Link
                    to={i.link}
                    key={i.title}
                    target={i.target}
                    className="text-white font-Barlow font-normal text-[22px] leading-[26px] uppercase text-left"
                  >
                    {i.title}
                  </Link>
                );
              })}
            </nav>
          </div>
          <div className="flex flex-col">
            <p className="text-white font-Barlow font-bold text-[28px] leading-[34px] uppercase text-left">
              <br />
            </p>
            <nav className="flex flex-col gap-y-5 mt-8">
              {menus2.map((i) => {
                return (
                  <NavLink
                    to={i.link}
                    key={i.title}
                    target={i.target}
                    className="text-white font-Barlow font-normal text-[22px] leading-[26px] uppercase text-left"
                  >
                    {i.title}
                  </NavLink>
                );
              })}
            </nav>
          </div>
          {/* <div className="flex flex-col ">
            <p className="text-white font-Barlow font-bold text-[28px] leading-[34px] uppercase text-left">
              Contact Us
            </p>
            <a
              href="mailto:vbs@hotqa.io"
              className="text-white font-Barlow mt-8 text-lg"
            >
              <span className="font-bold">Email:</span> vbs@hotqa.io
            </a>

            <div className="flex flex-row gap-x-8 mt-4 items-center justify-center">
              <a
                href="https://twitter.com/VBSVietnam"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={process.env.PUBLIC_URL + "/assets/twitter.png"}
                  alt="twitter"
                />
              </a>
              <a
                href="https://www.linkedin.com/company/vietnamblockchainsummit/?viewAsMember=true"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={process.env.PUBLIC_URL + "/assets/linkedin.png"}
                  alt="linkedin"
                />
              </a>
              <a href="https://t.me/VBS2022" target="_blank" rel="noreferrer">
                <img
                  src={process.env.PUBLIC_URL + "/assets/telegram.png"}
                  alt="telegram"
                />
              </a>
              <a
                href="https://www.facebook.com/vietnamblockchainsummit"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={process.env.PUBLIC_URL + "/assets/facebook.png"}
                  alt="facebook"
                />
              </a>
            </div>
          </div> */}

          <div className="flex flex-col mt-12">
            <div className="flex flex-row items-center justify-center gap-x-16 gap-y-10">
              <img
                src={process.env.PUBLIC_URL + "/assets/VBS2023.png"}
                alt="twitter"
                className="max-w-[110px]"
              />
              <a
                href="https://vinasa.org.vn/Default.aspx?sname=vinasa-en&sid=5&pageid=3145"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={process.env.PUBLIC_URL + "/assets/vinasa.png"}
                  alt="vinasa"
                  className="max-w-[48px]"
                />
              </a>
              <a
                href="https://blockchain.vn/en"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={
                    process.env.PUBLIC_URL +
                    "/assets/blockchain-association.png"
                  }
                  alt="blockchain"
                  className="max-w-[140px]"
                />
              </a>
            </div>
            <p className="text-white font-Roboto text-lg text-center mt-12">
              VBS is organized annually by VINASA & VBA.
            </p>
            <div className="flex flex-row gap-x-8 mt-4 items-center justify-between">
              <a
                href="https://twitter.com/VBSVietnam"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={process.env.PUBLIC_URL + "/assets/twitter.png"}
                  alt="twitter"
                />
              </a>
              <a
                href="https://www.linkedin.com/company/vietnamblockchainsummit/?viewAsMember=true"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={process.env.PUBLIC_URL + "/assets/linkedin.png"}
                  alt="linkedin"
                />
              </a>
              <a href="https://t.me/VBS2022" target="_blank" rel="noreferrer">
                <img
                  src={process.env.PUBLIC_URL + "/assets/telegram.png"}
                  alt="telegram"
                />
              </a>
              <a
                href="https://www.facebook.com/vietnamblockchainsummit"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={process.env.PUBLIC_URL + "/assets/facebook.png"}
                  alt="facebook"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}
