import { MediaAndCommunity2024 } from "../store/2024-media-and-community";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination } from "swiper";
import { chunk } from "../util/chunk";

export function MediaCommunity2024() {
  return (
    <div id="Partners" className="py-32 bg-media-partner">
      <h3 className="container mx-auto uppercase text-white font-Barlow font-bold text-4xl sm:text-5xl md:text-[54px] text-center  px-4">
        VBS 2024{" "}
        <span className="sub-gradient2">MEDIA & COMMUNITY PARTNERS</span>
      </h3>

      <div className="container mx-auto px-4">
        <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-4 md:gap-7 lg:gap-[2.62rem] mt-20">
          {MediaAndCommunity2024.map((i, index) => {
            return (
              <div className="col-span-1 bg-gradient-to-l to-[rgba(0,12,125,0.2)] from-[rgba(113,47,117,0.2)] h-20 rounded p-2">
                <a
                  href={i.link}
                  target="_blank"
                  rel="noreferrer"
                  key={`media-partner-${index}`}
                  className="block overflow-hidden h-full w-full"
                >
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      `/assets/2024-media-and-community/${i.img}`
                    }
                    alt={`${i.img}`}
                    className="w-full h-full object-contain"
                  />
                </a>
              </div>
            );
          })}
        </div>

        <div className="flex items-center justify-center mt-20">
          <a
            href="https://form.jotform.com/223469183033455"
            target="_blank"
            className="rounded-[20px] bg-btn uppercase font-Barlow font-bold text-[28px] leading-none text-white px-6 sm:px-[79px] py-[29px] text-center"
            rel="noreferrer"
          >
            Become a 2024 Media partner
          </a>
        </div>
      </div>
    </div>
  );
}
