export function HomeTopic() {
  const data = [
    {
      img: "/assets/blockchain.gif",
      label1: "Blockchain",
      label2: "Technology",
    },
    {
      img: "/assets/web3.gif",
      label1: "Regulatory<br/>Framework",
      label2: undefined,
    },
    // { img: "/assets/cryto.gif", label: "CRYPTO<br/>CURRENCY" },
    { img: "/assets/defi.gif", label1: "Defi", label2: "+ Fintech" },
    { img: "/assets/nft.gif", label1: "NFTs", label2: "+ Metaverse, DAOs" },
  ];

  return (
    <div className="container w-full mx-auto bg-topic p-4 flex flex-col mt-28 max-w-[1200px]">
      <h3 className="font-Barlow uppercase font-bold text-[28px] leading-[1.22] mt-4">
        Key topics
      </h3>
      <div className="inline-grid grid-cols-2 lg:grid-cols-4 gap-4 sm:gap-9 items-start justify-center">
        {data.map((item) => {
          return (
            <div
              key={`topic-${item.label1}`}
              className="flex items-center justify-start flex-col p-4 md:py-7 md:px-10"
            >
              <img
                src={process.env.PUBLIC_URL + item.img}
                alt={item.label1}
                className="max-w-[111px] max-h-[111px] w-full"
              />
              <p className="font-Barlow uppercase font-bold text-xl md:text-[28px] leading-[1.22] pt-6 text-center">
                <span dangerouslySetInnerHTML={{ __html: item.label1 }}></span>
                {item.label2 && <br />}
                {item.label2 && (
                  <span className="font-normal">{item.label2}</span>
                )}
              </p>
            </div>
          );
        })}
      </div>
    </div>
  );
}
