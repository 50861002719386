const image = [
  "logo_beincrypto.png",
  "logo_phocapblockchain.png",
  "logo_ZEX PR Wire.png",
  "logo_Crypto Chain Wire.png",
  "logo_cointelegraph.png",
  "logo_blockbeats.png",
  "logo_BlockTides.png",
  "logo_Asia Token Fund.png",
  "logo_Coinvoice White.png",
  "logo_web3daily.png",
  "logo_BlockDaemon.png",
  "logo_Coin Edition.png",
  "logo_Icoholder.png",
  "logo_METAHUB.png",
  "logo_TodayqNews.png",
  "logo_CryptoNewsZ.png",
  "logo_questn.png",
  "logo_Cointime.png",
  "logo_Coinlive.png",
  "logo_Taskon.png",
  "logo_bigcoin.png",
  "logo_coinbold.png",
  "logo_bitnews.png",
  "logo_CryptoXpert.png",
  "logo_BLCKCHN.png",
  "logo_BlockchainWork.png",
  "logo_Attirer.png",
  "logo_The Blockopedia.png",
  "logo_cryptoInsights.png",
  "logo_Blockchain Marketing Việt Nam.png",
  "logo_ABCstation.png",
  "logo_Hackernoon.png",
  "logo_Cryptoken Media.png",
  "logo_Cryptoniteuae.png",
  "logo_DroomDroom.png",
  "logo_Coinotag.png",
  "logo_cryptovietinfo.png",
  "logo_icon100.png",
  "logo_The Coin Republic.png",
  "logo_cryptoday.png",
  "logo_Followin.png",
  "logo_Defi Learn.png",
  "logo_Polish Blockchain.png",
  "logo_Cryptopolitan.png",
  "logo_bd ventures.png",
  "logo_coincu.png",
  "logo_fomogroup.png",
  "logo_dcck.png",
  "logo_realcoin.png",
  "logo_ZGmedia.png",
];

const link = [
  "vn.beincrypto.com",
  "phocapblockchain.net",
  "www.zexprwire.com",
  "www.cryptochainwire.com",
  "#",
  "https://www.theblockbeats.info/",
  "https://www.blocktidesmedia.com/",
  "https://asiatokenfund.com/",
  "https://coinvoice.net/",
  "https://twitter.com/Web3DailyReal",
  "https://blockdaemon.com",
  "https://www.c",
  "https://icoholder.com/",
  "https://wemeta.gg",
  "https://news.todayq.com/",
  "https://www.cryptonewsz.com/",
  "https://questn.com/",
  "https://www.cointime.com/",
  "www.coinlive.com",
  "https://www.taskon.xyz",
  "https://bigcoinvietnam.com/",
  "https://coinbold.io",
  "https://bitnews.social/",
  "https://twitter.com/_cryptoxpert",
  "https://blckchn.agency/",
  "https://insider.blockchainwork.net/",
  "https://attirer.io",
  "https://theblockopedia.com/",
  "https://crypto-insights.news/",
  "https://www.facebook.com/blockchainmarketingvietnam/",
  "https://abcstation.substack.com/",
  "https://hackernoon.com",
  "https://cryptoken.media/",
  "https://www.cryptonite.ae/",
  "https://droomdroom.com/",
  "https://en.coinotag.com",
  "https://cryptoviet.info/",
  "https://icoin100.com/",
  "https://www.thecoinrepublic.com/?playlist=525e6c2&video=f9ce186",
  "https://cryptoday.com/vi",
  "https://followin.io/",
  "https://defilearn.net/",
  "https://www.blockchain-polska.org",
  "https://www.cryptopolitan.com/",
  "https://www.bdventures.vn/",
  "https://news.coincu.com/",
  "https://fomosignals.com https://fomotimes.net https://Fomonews.net",
  "https://danchoicallkeo.com/",
  "https://linktr.ee/therealcoin",
  "https://blockchainhub.news",
];

const name = [
  "Beincrypto",
  "Diễn đàn Phổ cập Blockchain",
  "ZEX PR Wire",
  "Crypto Chain Wire",
  "Cointelegraph",
  "BlockBeats",
  "Block Tides",
  "Asia Token Fund",
  "Coinvoice",
  "Web 3 Daily",
  "BlockdaemonHQ",
  "Coin Edition Media",
  "ICOHOLDER",
  "Metahub",
  "Todayq News",
  "CryptoNewsZ",
  "QuestN",
  "Cointime",
  "Coinlive",
  "TaskOn",
  "Bigcoin Viet Nam",
  "Coinbold",
  "Bitnews Social",
  "CryptoXpert",
  "BLCKCHN MKT Agency",
  "BlockchainWork Insider",
  "Attirer",
  "The Blockopedia",
  "Crypto Insights",
  "Blockchain Marketing Việt Nam Community",
  "ABC Station",
  "HackerNoon",
  "Cryptoken Media",
  "CryptoniteUae",
  "DroomDroom Corporation",
  "COINOTAG",
  "Nghiện Coin / CryptoVietInfo",
  "iCoin100",
  "The Coin Republic",
  "Cryptoday Viet Nam",
  "Followin",
  "Defi Learn",
  "Polish Blockchain Association",
  "Cryptopolitan",
  "BD Ventures",
  "Coincu Media",
  "Fomogroup",
  "Dân Chơi Call Kèo",
  "The Real Coin",
  "ZG Media",
];

export const MediaAndCommunity2024 = image.map((i, index) => {
  return { img: i, link: link[index], name: name[index] };
});
